@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
  /* font-family: "IBM Plex Mono", monospace; */
}

a {
  color: black;
  text-decoration: none;
}

img {
  width: 100%;
}

.notfound {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
}

#navbar {
  padding: 15px;
}

#navbar .container-fluid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#navbar .container-fluid figure {
  width: 200px;
  margin: 0;
}

#navbar .container-fluid ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
}

#navbar .container-fluid ul li {
  margin: 0 10px;
}

#login input,
#login button {
  width: 100%;
  margin-bottom: 20px;
}

div#loader {
  background-color: #000000bf;
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

#profile input,
#profile button {
  width: 100%;
  margin-bottom: 20px;
}

.galeria {
  background-color: black;
}

figure.galleryFigure {
  width: 50%;
  float: left;
  margin-bottom: 0;
}

figure.galleryFigure img {
  filter: grayscale();
}

.filterList {
  list-style: none;
}

.filterList li {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 15px;
}

.filterList li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.size-selector {
  display: flex;
  width: 100%;
  gap: 30px;
}

.size-selector div {
  text-transform: uppercase;
  cursor: pointer;
  padding: 15px;
  border: 1px solid black;
}

.size-selector div:hover,
.sizeSelected {
  background-color: black;
  color: white;
}

.fakeCarrousel {
  display: flex;
  overflow: scroll;
  margin-bottom: 50px;
}

span.cartCounter {
  position: absolute;
  font-size: 12px;
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: -28px;
  right: -28px;
}

.carritoCompras {
  width: 90vw;
}

@media screen and (min-width: 800px) {
  .carritoCompras {
    width: 40vw;
  }
}

section .receipt {
  margin: 0;
  font-size: 12px;
  position: relative;
  font-family: "courier";
}
.serif {
  font-family: serif;
}
.sans-serif {
  font-family: "sans-serif";
}
.bold {
  font-weight: 700;
}
.x-bold {
  font-weight: 900;
  text-shadow: 0px 0px 1px #000;
}
.hr,
.hr-sm,
.hr-lg {
  border-bottom: 1.5px dashed #333;
  margin: 10px 0;
}
.hr-sm {
  width: 30%;
  float: right;
}
.hr-lg {
  width: 100%;
}
.col2 {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.container-ticket {
  background: #e6e6e6;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 50px 0;
  box-sizing: border-box;
}
.container-ticket .ticket {
  overflow: hidden;
  cursor: default;
  position: relative;
  width: 300px;
  padding: 10px 20px;
  background: #fff
    url(https://static.licdn.com/scds/common/u/images/apps/payments/textures/texture_paper_304x128_v1.png);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}
.container-ticket .ticket .head-ticket {
  text-align: center;
  padding: 0px 17px;
}
.container-ticket .ticket .head-ticket p {
  font-size: 14px;
}
.container-ticket .ticket .head-ticket p:nth-child(1) {
  font-size: 18px;
}
.container-ticket .ticket .head-ticket p:nth-child(6),
.container-ticket .ticket .head-ticket p:nth-child(7) {
  font-size: 12px;
  text-align: left;
}
.container-ticket .ticket .head-ticket .code-barre {
  height: 50px;
  display: flex;
  justify-content: space-between;
  margin-left: -17px;
  margin-right: -17px;
  margin-top: 5px;
}
.container-ticket .ticket .head-ticket .code-barre span {
  height: 100%;
  width: 10px;
  display: inline-block;
  background: #333;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(1) {
  width: 7px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(2) {
  width: 3px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(3) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(4) {
  width: 2px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(5) {
  width: 7px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(6) {
  width: 5px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(7) {
  width: 2px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(8) {
  width: 5px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(9) {
  width: 3px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(10) {
  width: 6px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(11) {
  width: 1px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(12) {
  width: 7px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(13) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(14) {
  width: 3px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(15) {
  width: 6px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(16) {
  width: 4px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(17) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(18) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(19) {
  width: 7px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(20) {
  width: 7px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(21) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(22) {
  width: 2px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(23) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(24) {
  width: 5px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(25) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(26) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(27) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(28) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(29) {
  width: 5px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(30) {
  width: 2px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(31) {
  width: 5px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(32) {
  width: 3px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(33) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(34) {
  width: 1px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(35) {
  width: 2px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(36) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(37) {
  width: 3px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(38) {
  width: 5px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(39) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(40) {
  width: 4px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(41) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(42) {
  width: 4px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(43) {
  width: 6px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(44) {
  width: 4px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(45) {
  width: 6px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(46) {
  width: 3px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(47) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(48) {
  width: 4px;
  margin-right: 2px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(49) {
  width: 1px;
  margin-right: 1px;
}
.container-ticket .ticket .head-ticket .code-barre span:nth-child(50) {
  width: 7px;
  margin-right: 1px;
}
.container-ticket .ticket .body-ticket {
  padding: 0px 17px;
}
.container-ticket .ticket .body-ticket .produits {
  margin: 30px 0;
}
.container-ticket .ticket .body-ticket .carte {
  text-align: justify;
  text-align-last: center;
}
.container-ticket .ticket .body-ticket .carte .title-carte {
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -2px;
}
.container-ticket .ticket .footer-ticket {
  padding: 0px 17px;
}
.container-ticket .ticket .footer-ticket .title-footer {
  font-size: 16px;
  font-weight: 900;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
  text-align: center;
  letter-spacing: 2px;
}

.orderData {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 30px;
  height: 90px;
  overflow: hidden;
  cursor: pointer;
}

.openData {
  height: auto;
  max-height: 400px;
  overflow: scroll;
}

#MenuIndex {
  margin: 0;
  height: 700vh;
  overflow: hidden;
  position: absolute;
  inset: 0;
  z-index: 1000;
  background-color: white;
}

#MenuIndex #circleWrap {
  position: fixed;
  width: 100px;
  height: 100px;
  top: 50%;
  left: -300px;
}

#MenuIndex svg {
  position: absolute;
  height: 100vh;
  top: 50%;
  left: 50%;
  overflow: visible;
}

#MenuIndex .item {
  width: 250px;
  height: 80px;
  font-size: 37px;
  line-height: 83px;
  color: #484848;
  overflow: hidden;
  font-weight: 800;
  cursor: pointer;
}

#MenuIndex .item:hover {
  color: red;
}

#MenuIndex #circle {
  fill: white;
}

#BoltHouse {
  overflow: hidden;
}

#BoltHouse {
  margin-top: 50px;
  transform-style: preserve-3d;
}

#BoltHouse .screen {
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

#BoltHouse #wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  perspective: 500px;
  perspective-origin: center;
}

#BoltHouse .page {
  height: 800px;
  width: 100%;
  max-width: 500px;
  position: absolute;
  left: 50%;
  transition: transform 0.75s ease, top 0.75s ease;
  transform: rotateX(-45deg) translateX(-50%);
  transform-origin: 0% 0%;
  border-radius: 10px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  cursor: pointer;
  background-position: center center;
}

@media only screen and (min-width: 700px) {
  #BoltHouse .page {
    max-width: 800px;
    height: 500px;
  }
}

#BoltHouse .page:hover {
  filter: invert();
}

#BoltHouse #marketMarket.page {
  top: 0px;
  background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726626886/bolt/lecimcdsgj64wshqghnt.png);
}
#BoltHouse #renata.page {
  top: 200px;
  background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726626974/bolt/xeq82kiasgrylgdkvgvq.png);
}
#BoltHouse #blog.page {
  top: 400px;
  background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726626999/bolt/xyfisr9xmjhv0dobwq7k.png);
}
#BoltHouse #boltBook.page {
  top: 600px;
  background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627026/bolt/hashh68wpodtdu3g0gpz.png);
}
#BoltHouse #exlusive.page {
  top: 800px;
  background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627085/bolt/yhafdcrq4byg3vpm2xpk.png);
}

@media only screen and (min-width: 700px) {
  #BoltHouse #marketMarket.page {
    background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627110/bolt/lxyi0gcaiuk9djzju4vs.png);
  }
  #BoltHouse #exlusive.page {
    background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627133/bolt/bngs9hwi9gd7jl4zhgwm.png);
  }
  #BoltHouse #blog.page {
    background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627159/bolt/c68xo1yozeryjyb3qatn.png);
  }
  #BoltHouse #boltBook.page {
    background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627182/bolt/alb9y2qpgsekiyms4b9j.png);
  }
  #BoltHouse #renata.page {
    background-image: url(https://res.cloudinary.com/dazn99jie/image/upload/v1726627208/bolt/zb42i2afyax1c8zu9w5l.png);
  }
}

i {
  cursor: pointer;
}

.videoBanner {
  width: 100%;
  height: calc(100vh - 62px);
  inset: 0;
  object-fit: cover;
  filter: grayscale(1);
  margin-bottom: -10px;
}

button {
  -webkit-appearance: none !important;
  -webkit-border-radius: none !important;
  border-radius: 0 !important;
  background-color: rgb(239, 239, 239) !important;
  border-width: 2px !important;
  border-style: outset !important;
  padding: 3px !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}

input {
  -webkit-appearance: none !important;
  color: black !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  border: 1px solid black;
  border-radius: 3px !important;
  padding: 5px 10px !important;
}

:root {
  --widthLic: 1;
}

@media screen and (max-width: 1200px) {
  :root {
    --widthLic: 1.1;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --widthLic: 1.2;
  }
}

@media screen and (max-width: 450px) {
  :root {
    --widthLic: 1.4;
  }
}

@media screen and (max-width: 370px) {
  :root {
    --widthLic: 2;
  }
}

#license {
  box-shadow: calc(1px / var(--widthLic)) calc(1px / var(--widthLic))
    calc(4px / var(--widthLic)) #222;
  border-radius: calc(25px / var(--widthLic));
  min-width: calc(500px / var(--widthLic));
  width: calc(500px / var(--widthLic));
  height: calc(316px / var(--widthLic));
  min-height: calc(316px / var(--widthLic));
  position: relative;
  display: flex;
  background-color: #ffffee;
  font-family: Arial;
  overflow: hidden;
}

#license .left {
  flex: calc(1 / var(--widthLic));
}

#license .photo {
  border-top-left-radius: calc(25px / var(--widthLic));
  height: calc(200px / var(--widthLic));
  margin-bottom: calc(10px / var(--widthLic));
  background-color: #45f;
}

#license .barcode {
  height: calc(33.3px / var(--widthLic));
  background: linear-gradient(
    90deg,
    white 0%,
    white calc(20% / var(--widthLic)),
    black calc(20% / var(--widthLic)),
    black calc(50% / var(--widthLic)),
    white calc(50% / var(--widthLic)),
    white calc(55% / var(--widthLic)),
    black calc(55% / var(--widthLic)),
    black calc(70% / var(--widthLic)),
    white calc(70% / var(--widthLic)),
    white calc(80% / var(--widthLic)),
    black calc(80% / var(--widthLic)),
    black
  );
  background-size: calc(12px / var(--widthLic));
}

#license .address {
  font-size: calc(0.9em / var(--widthLic));
  font-weight: 800;
  padding-top: calc(3px / var(--widthLic));
  padding-left: calc(7px / var(--widthLic));
  width: calc(130% / var(--widthLic));
}

#license .right {
  flex: calc(2 / var(--widthLic));
  padding-left: calc(4px / var(--widthLic));
}

#license .header {
  font-weight: 800;
  display: flex;
  color: #78f;
  margin: calc(-7px / var(--widthLic)) 0 calc(-10px / var(--widthLic))
    calc(-2px / var(--widthLic));
}

#license .state {
  flex: calc(30 / var(--widthLic));
  font-size: calc(4em / var(--widthLic));
  text-shadow: calc(3px / var(--widthLic)) calc(3px / var(--widthLic))
    calc(0px / var(--widthLic)) black;
  padding-right: calc(10px / var(--widthLic));
  letter-spacing: calc(-5px / var(--widthLic));
  transform: scale(calc(1 / var(--widthLic)), calc(1.14 / var(--widthLic)));
}

#license .docname {
  font-weight: 800;
  flex: calc(20 / var(--widthLic));
  font-size: calc(1.3em / var(--widthLic));
  display: flex;
  align-items: center;
  letter-spacing: calc(-3px / var(--widthLic));
}

#license .rainbow {
  position: absolute;
  top: calc(70px / var(--widthLic));
  left: calc(165px / var(--widthLic));
}

#license .number {
  position: relative;
  margin-bottom: calc(10px / var(--widthLic));
}

#license .number .high {
  font-size: calc(1.74em / var(--widthLic));
}

#license .dates {
  position: relative;
}

#license .high {
  font-size: calc(1.34em / var(--widthLic));
}

#license table {
  position: relative;
  font-size: calc(1em / var(--widthLic));
}

#license th,
#license td {
  font-size: calc(0.85em / var(--widthLic));
  font-weight: 400;
  text-align: center;
}

.redBox {
  background-color: red;
  padding: 10px;
  text-align: justify;
}

.logoIframe {
  width: 100%;
  height: calc(100vh - 62px);
  overflow: hidden;
}

.openBoltHouse {
  position: fixed;
  bottom: 15px;
  left: 15px;
  background-color: red;
  color: white;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 40px;
  font-family: "IBM Plex Mono";
  cursor: pointer;
  z-index: 10;
}

.hovRed:hover {
  background-color: rgba(255, 0, 0, 0.666);
}

.listImg {
  filter: grayscale(1);
  transition: all 1s ease;
  aspect-ratio: 2 / 3;
  object-fit: cover;
}

.listImg:hover {
  filter: none;
  transform: scale(1.1);
}

.bannerCommerce {
  filter: brightness(0.3);
  height: 50vh;
  object-fit: cover;
}

.menuItemStore {
  width: 50vw;
  height: 50vw;
  overflow: hidden;
  position: relative;
}

.menuItemStoreTitle {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.278);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 1s;
}

.menuItemStoreTitle:hover {
  background-color: rgba(0, 0, 0, 0.64);
}

#Market {
  background-color: #808080ad;
  min-height: 100vh;
}

#Market .col-6.col-lg-3 {
  border: 0.5px solid black;
  padding: 30px 0px;
  cursor: pointer;
}

#Market h1 {
  width: 100%;
  font-size: 7.9vw;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
}

#Book .bookImage {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#Book img {
  width: calc(100% / 5);
  object-fit: cover;
}

@media screen and (max-width: 600px) {
  #Book img {
    width: calc(100% / 4);
  }
}

@media screen and (max-width: 400px) {
  #Book img {
    width: calc(100% / 3);
  }
}

.wsp {
  background-color: #24d366 !important;
  border: 1px solid black;
}

div#Book {
  background-color: black;
}

div#Book h1,
div#Book p,
div#Book b {
  color: rgb(0, 255, 0);
  text-transform: capitalize;
}

#Blog {
  background-color: #f670ca;
  min-height: 100vh;
}

#Blog img {
  width: 100%;
}

#Blog {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}
#Blog h1,
h2,
h3 {
  text-transform: capitalize;
}
#Blog h1 {
  font-weight: bold;
  margin-bottom: 15px;
  cursor: pointer;
  text-decoration: underline;
}
#Blog h2 {
  font-weight: bold;
  margin-bottom: 45px;
}
#Blog p {
  margin-bottom: 1em;
}
#Blog ul {
  margin-bottom: 1em;
}

.house-button {
  background-color: red !important;
  border: 1px solid black;
  color: black;
}

div#exlusive {
  display: none;
}

#OrderCreated p {
  text-transform: none;
}

.shipOption {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.shipOption .active {
  background-color: red !important;
}

.uneteCanvas {
  position: fixed;
  inset: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.632);
  display: flex;
  justify-content: center;
  align-items: center;
}

.uneteCanvas img {
  width: 300px;
  cursor: pointer;
}

.uneteCanvas figure {
  position: relative;
}

.uneteCanvas .closeCanvas {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: black;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
}

.btn-mp {
  background-color: #009ee3 !important; /* Color de fondo azul de MercadoPago */
  color: white !important; /* Color del texto en blanco */
  padding: 10px 20px !important; /* Espaciado interno */
  border: none !important; /* Sin bordes */
  border-radius: 5px !important; /* Bordes redondeados */
  font-size: 16px !important; /* Tamaño del texto */
  font-family: "Arial", sans-serif !important; /* Fuente */
  cursor: pointer !important; /* Cursor de mano al pasar sobre el botón */
  transition: background-color 0.3s ease !important; /* Transición suave al cambiar de color */
}

.btn-mp:hover {
  background-color: #007bbd !important; /* Color de fondo al pasar el ratón */
}

.btn-mp:active {
  background-color: #005b94 !important; /* Color de fondo al hacer clic */
}

.payBlock {
  max-width: 350px;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 30px;
  border-radius: 10px;
}

div#OrderCreated {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
